import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { APIURL, LANG } from '../../IWWJsCode';
import { useApiData } from '../../Contexts/ApiDataContext';

export default function Footer() {
  let { universities, gettouch } = useApiData();
  let [Footer, SetFooter] = useState();

  let { lang } = LANG();

  useEffect(() => {
    Getfooterdata();
  }, []);

  async function Getfooterdata() {
    let { data } = await axios.get(APIURL(lang + '/web/footerAbout')).catch((err) => {
      console.error(err);
    });
    SetFooter(data);
  }

  return (
    <>
      <div className='footer-section'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-md-10 m-auto'>
              <div className='row py-5 my-5'>
                <div className='col-12 col-md-12'>
                  {Footer ? (
                    <>
                      {Footer.image && Footer.image.length ? <img src={Footer.image} className='w-25' alt='' /> : ''}
                      <h5 className='text-white'>{Footer.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Footer.htmlDescription,
                        }}></p>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className='col-12 col-md-6'>
                  <div className='sub-footer d-none'>
                    <h3 className='text-white'>Subscribe Now</h3>
                    <div className='input-group mb-3'>
                      <input type='email' className='form-control p-3' placeholder='Email' aria-label="Recipient's username" aria-describedby='button-addon2' />
                      <div className='input-group-append'>
                        <button className='btn btn-succe  border-0 ' type='button' id='button-addon2'>
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-4'>
                  {gettouch != null ? (
                    <>
                      <h4>Keep in touch</h4>
                      <ul className='list-unstyled'>
                        {gettouch && gettouch.address && gettouch.address.length ? (
                          <li className='text-white'>
                            <i className={'fas fa-map-marker-alt mx-2 text-white'}></i>
                            {gettouch.address}
                          </li>
                        ) : (
                          ''
                        )}

                        {gettouch && gettouch.telephone && gettouch.telephone.length ? (
                          <li className='text-white'>
                            <Link to={'tel:' + gettouch.telephone} className=' text-white text-decoration-none'>
                              <i className={'fas fa-phone mx-2 text-white'}></i>
                              {gettouch.telephone}
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}

                        {gettouch && gettouch.whatsapp && gettouch.whatsapp.length ? (
                          <li className='text-white'>
                            <Link to={'https:wa.me/' + gettouch.whatsapp} target='_blank' className='text-white text-decoration-none'>
                              <i className={'fab fa-whatsapp mx-2 text-white'}></i>
                              {gettouch.whatsapp}
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}

                        {gettouch && gettouch.email && gettouch.email.length ? (
                          <li className='text-white'>
                            <Link to={'mailto:' + gettouch.email} className='text-white text-decoration-none'>
                              <i className={'fas fa-envelope mx-2 text-white'}></i>
                              {gettouch.email}
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}

                        {gettouch && gettouch.social && gettouch.social.length > 0 ? (
                          <li>
                            <div className='social'>
                              <ul className='list-unstyled d-flex'>
                                {gettouch.social.map((el, i) => (
                                  <li key={i} className='m-2'>
                                    <Link to={el.url} title={el.title} target='_blank'>
                                      <i className={el.faClass + ' fs-5 text-white'}></i>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </li>
                        ) : (
                          ''
                        )}
                      </ul>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div className='col-12 col-md-4'>
                  {universities && universities.length ? (
                    <>
                      <h4>Our Universities</h4>
                      <ul>
                        {universities.map((el) => (
                          <li key={el.id}>
                            <NavLink className='text-white text-decoration-none' to={'/Courses/' + el.id}>
                              <i className='fas fa-chevron-right me-2'></i>
                              {el.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div className='col-12 col-md-4'>
                  <h4>Quick links</h4>
                  <ul>
                    <li key={'po'}>
                      <Link to='/policy' className='text-white text-decoration-none'>
                        <i className='fas fa-chevron-right me-2'></i>User Policy
                      </Link>
                    </li>
                    <li>
                      <Link to='/terms' className='text-white text-decoration-none'>
                        <i className='fas fa-chevron-right me-2'></i>Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={'/contactus'} className='text-white text-decoration-none'>
                        <i className='fas fa-chevron-right me-2'></i>Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='comp2'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <h6 className='text-white text-decoration-none'>
                  Copyright @ Smartvision <span>2023</span>
                </h6>
              </div>

              <div className='col-12 col-md-6'>
                <h6 className='text-white text-decoration-none'>
                  Powered By :
                  <Link to='https://www.ideaworldweb.com/' className='text-white text-decoration-none' target='_blank'>
                    Idea World Web
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
