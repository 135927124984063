import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import settings from '../settings.json';

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}
export function UserProvider({ children }) {
  let [user, setUser] = useState();
  let [loadingUser, setLoadingUser] = useState();

  async function getUser() {
    setLoadingUser(true);
    await axios
      .get(settings.APiUrl + 'auth/user')
      .then((resp) => {
        if (resp && resp.data && resp.data.success && resp.data.user) {
          setUser(resp.data.user);
        }else{
          setUser(null)
        }
      })
      .catch(function (error) {
        console.error('UserContext.getUser:ERROR', error);
      });
    setLoadingUser(false);
  }

  const isUserChapter = (chapter) => {
    if (user == null) return false;

    let isChapter = user.chapters && user.chapters.length && user.chapters.some((i) => i.id === chapter.id);
    let isSection = user.sections && user.sections.length && user.sections.some((i) => i.id === chapter.sectionId);
    let isCourse = user.courses && user.courses.length && user.courses.some((i) => i.id === chapter.courseId);
    return isChapter || isSection || isCourse;
  };

  const isUserSection = (section) => {
    if (user == null) return false;
    
    let isSection = user.sections && user.sections.length && user.sections.some((i) => i.id === section.id);
    let isCourse = user.courses && user.courses.length && user.courses.some((i) => i.id === section.courseId);
    return isSection || isCourse;
  };

  function isUserCourse(courseId) {
    if (user == null) return false;

    return user.courses && user.courses.length && user.courses.some((i) => i.id === courseId);
  }

  useEffect(() => {
    getUser();
  }, []);

  return <UserContext.Provider value={{ user, loadingUser, setUser, getUser, isUserChapter, isUserSection, isUserCourse }}>{children}</UserContext.Provider>;
}
