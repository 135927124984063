/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stagger, useAnimate, usePresence } from 'framer-motion';

import { USER, APIURL, CART, LANG, Loader } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';

export default function Cart() {
  let [cartItemsData, setCartItemsData] = useState();
  let [termsAgree, setTermsAgree] = useState(false);
  let [total, setTotal] = useState(0);

  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  let { cartItems, emptyCart, removeItem } = CART();
  const { user } = USER();
  const { lang, currency } = LANG();
  const { loading, setLoading } = Loader();
  let [message, setMessage] = useState({ success: null, message: null });
  // let [checkoutApiResponse, setCheckoutApiResponse] = useState();
  let [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getCartItemsData(cartItems, lang);
  }, [cartItems]);

  async function getCartItemsData(items, lng) {
    setLoading(true);
    console.log('cartItems && cartItems.length', items && items.length);
    if (items && items.length) {
      fetch(APIURL(lng + '/payments/cart'), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(items),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.cartData) setCartItemsData(json.cartData);
          if (json.total) setTotal(json.total);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setCartItemsData(null);
    }
    console.log('cartItemsData', cartItemsData);
  }

  async function checkout(items, lng) {
    setSubmitting(true);
    fetch(APIURL(lng + '/payments/pay'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(items),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.message) setMessage(json.message);
        // if (json.checkoutApiResponse) setCheckoutApiResponse(json.checkoutApiResponse);
        if (json.redirectUrl) window.location.href = json.redirectUrl;
      });
    setSubmitting(false);
    console.log('cartItemsData', cartItemsData);
  }

  useEffect(() => {
    if (isPresent) {
      const enterAnimate = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 3, delay: stagger(1) });
      };
      enterAnimate()
    } else {
      const exitAnimate = async () => {
        await animate(scope.current, { opacity: [1, 0] }, { duration: 3, delay: stagger(1) });
        safeToRemove()
      };
      exitAnimate()
    }
  });

  return (
    <>
      <div className='container py-4'>
        <div className='row justify-content-center text-center'>
          <div className='col-12 col-lg-8'>
            <div className='list-group-item d-flex justify-content-between rounded bg-text-dark bg-dark mb-2'>
              <div className='ms-2 me-auto d-flex'>
                <h3 className='fw-bold text-light'>
                  {cartItemsData && cartItemsData.length ? (
                    <>
                      {'('}
                      {cartItemsData && cartItemsData.length}
                      {')'} Cart Items
                    </>
                  ) : (
                    'Empty'
                  )}
                </h3>
              </div>
              <button
                type='button'
                className='btn btn-sm btn-outline-dark float-end'
                onClick={(e) => {
                  emptyCart();
                }}>
                Clear <i className='fas fa-times'></i>
              </button>
            </div>
            <ol className='list-group list-group-numbered mb-2'>
              {cartItemsData && cartItemsData.length > 0 ? (
                cartItemsData.map((el, i) => (
                  <li ref={scope} key={i} className={'list-group-item d-flex justify-content-between align-items-start' + (el.isParentInCart ? ' opacity-50' : '')}>
                    <div className='ms-2 text-start me-auto'>
                      <div className='fw-bold text-wrap'>{el.name} </div>
                      <span className='badge bg-secondary'>{el.type}</span>
                      {el.error ? <span className={'badge text-wrap m-1 text-bg-' + (el.isParentInCart ? 'warning' : 'danger')}>{el.error}</span> : ''}
                      {el.note ? <span className='badge text-wrap text-bg-success m-1'>{el.note}</span> : ''}
                    </div>
                    {el.pricePefore ? (
                      <>
                        <span className={'btn btn-sm btn-light mx-1 text-decoration-line-through'}>{el.pricePefore.toFixed(2)}</span>
                      </>
                    ) : (
                      ''
                    )}
                    <span className={'btn btn-light btn-sm w-25 fw-bold mx-1' + (el.isParentInCart ? ' text-decoration-line-through' : '')}>
                      {el.price.toFixed(2)} {currency}
                    </span>
                    <span
                      className='btn btn-outline-danger btn-sm mx-1'
                      onClick={(e) => {
                        removeItem(el.id);
                      }}>
                      <i className='fas fa-trash'></i>
                    </span>
                  </li>
                ))
              ) : (
                <>
                  {!loading ? (<li>
                    <h3 className='text-center text-muted text-secondary'>
                      Empty Cart <i className='fas fa-cart-arrow-down mx-2'></i>
                    </h3>
                  </li>) : ''}
                </>
              )}
            </ol>
            <SecLoader type={'cart'} />
          </div>
          <div className='col-12 col-lg-4'>
            {cartItemsData && total && cartItemsData.length > 0 ? (
              <>
                <ul className='list-group mb-3'>
                  <li className='list-group-item d-flex justify-content-between'>
                    <div>
                      <h3 className='my-0'>Total</h3>
                    </div>
                    {total && total > 0 ? (
                      <h4 className='text-muted fw-bold'>
                        {total.toFixed(2)} {currency}
                      </h4>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
                {message && message.message ? (
                  <div className={'badge ' + (message.success ? 'bg-success' : 'bg-warning text-dark')}>
                    <p>{message.message}</p>
                  </div>
                ) : (
                  ''
                )}
                {user && user.Active ? (
                  <>
                    {cartItemsData.some((i) => i.error && i.error !== '') ? (
                      <>
                        <div class='alert alert-warning text-start' role='alert'>
                          <h4 class='alert-heading'>Warning</h4>
                          <p>Please check your selected items to be sure before payment.</p>
                          <hr />
                          <p class='mb-0'>
                            Each item has its own yellow warning badge, <strong>read and then make your choice.</strong>
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='form-check form-check-inline text-start'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='termsAgreement'
                            onChange={(e) => {
                              setTermsAgree(!termsAgree);
                            }}
                          />
                          <label className={'form-check-label'} style={{ cursor: 'pointer' }} htmlFor='termsAgreement'>
                            I agree to
                          </label>{' '}
                          <Link href='/terms' className=''>
                            the terms and conditions
                          </Link>{' '}
                          and{' '}
                          <Link href='/policy' className=''>
                            User Policies.
                          </Link>
                        </div>
                        <button
                          type='button'
                          onClick={(e) => {
                            checkout(cartItems, lang);
                          }}
                          disabled={!termsAgree}
                          className='btn btn-outline-success mt-2 w-100 btn-lg'>
                          Checkout
                          <i className='fas fa-credit-card mx-1'></i>
                          <i className='fas fa-circle-notch mx-1 fa-spin float-end' style={{ display: submitting ? 'block' : 'none' }}></i>
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <Link className='btn btn-outline-light bttn mt-2 w-100 btn-lg' to={'/login'}>
                    Login and complete purchase
                  </Link>
                )}
              </>
            ) : (
              <>
                {(!cartItems || cartItems.length === 0) && loading === false ? (
                  <div class='alert alert-light text-start' role='alert'>
                    <h4 class='alert-heading'>Cart is empty</h4>
                    <p>Please select a course or semester to proceed with payment.</p>
                    <hr />
                    <Link to='/Courses' className='btn btn-light'>
                      Courses
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
