/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import footerLogo from '../../images/logo-footer.png';
import img1 from '../../images/cat-1.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { APIURL, LANG, USER, Loader } from '../../IWWJsCode';
import CourseBox from '../Materials/Partials/_CourseBox';

export default function Home() {
  let { setLoading } = Loader();
  let { user } = USER();
  let { lang } = LANG();
  let [Homedeteils, Sethomeintro] = useState();
  // let [Homeabout, Sethomeabout] = useState({});
  let [HomeMajor, Sethomemajor] = useState([]);
  let [Homecourses, SetCorsues] = useState([]);
  let [HomeInstuctors, SetInstuctors] = useState([]);
  let navigate = useNavigate();

  let validationSchema = Yup.object({
    title: Yup.string().required(),
    fName: Yup.string().required('Required').min(3, 'Min char is 3 ').max(10, 'Max char is 10'),
    lName: Yup.string().required('Required').min(3, 'Min char is 3 ').max(10, 'Max char is 10'),
    phoneNumber: Yup.string().required().min(9),
    username: Yup.string().required().email('enter vaild Email'),
  });

  const initialValues = {
    title: 'Mr',
    fName: '',
    lName: '',
    phoneNumber: '',
    username: '',
  };

  const registerData = JSON.parse(localStorage.getItem('RegisterData'));
  if (registerData !== null && registerData !== '') {
    if (registerData.title !== '') initialValues.title = registerData.title;
    if (registerData.fName !== '') initialValues.fName = registerData.fName;
    if (registerData.lName !== '') initialValues.lName = registerData.lName;
    if (registerData.phoneNumber !== '') initialValues.phoneNumber = registerData.phoneNumber;
    if (registerData.username !== '') initialValues.username = registerData.username;
  }

  const onSubmit = (values) => {
    localStorage.setItem('RegisterData', JSON.stringify(values));
    navigate('/register');
  };

  const Formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    setLoading(true);
    Gethomedata();
    // Gethomeabout();
    Gethomemajor();
    GetInstuctors();
    Getcourses();
    setLoading(false);
  }, []);

  async function Gethomedata() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeIntro'));
    if (data) Sethomeintro(data);
    setLoading(false);
  }
  async function Gethomemajor() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeMajors'));
    if (data) Sethomemajor(data);
    setLoading(false);
  }
  async function GetInstuctors() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeInstuctors'));
    if (data) SetInstuctors(data);
    setLoading(false);
  }
  async function Getcourses() {
    setLoading(true);
    let { data } = await axios.get(APIURL(lang + '/web/HomeCourses'));
    if (data) SetCorsues(data);
     setLoading(false);
  }

  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  };
  return (
    <>
      {(Homedeteils && Homedeteils.length) || user == null ? (
        <div className='row'>
          <div key={'form-request-section'} className='form-request-section'>
            <div className='container my-5 py-5'>
              <div className='row'>
                <div className={'d-flex align-items-center ' + (user == null ? 'col-md-6 col-sm-12' : 'col-md-12')}>
                  {Homedeteils && Homedeteils.length ? (
                    <div className='form-section-data'>
                      <h3 className='fs-2 text-white'>{Homedeteils.title}</h3>
                      <p
                        className='fs-5 text-white'
                        dangerouslySetInnerHTML={{
                          __html: Homedeteils.htmlDescription,
                        }}></p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {user == null ? (
                  <div className='col-md-6 col-sm-12'>
                    <div className='form-request'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-logo d-flex my-3'>
                            <img src={footerLogo} alt='' srcSet='' />
                            <h4 className='pt-3 text-white'>Subscribe Now</h4>
                          </div>
                        </div>

                        <form onSubmit={Formik.handleSubmit}>
                          <div className='d-flex justify-content-between'>
                            <div className='input-group'>
                              <select className='form-control' onChange={Formik.handleChange} value={Formik.values.title} id='title' name='title'>
                                <option key={'Mr'} onChange={Formik.handleChange} value='Mr'>
                                  Mr.
                                </option>
                                <option key={'Mrs'} onChange={Formik.handleChange} value='Mrs'>
                                  Mrs.
                                </option>
                                <option key={'Miss'} onChange={Formik.handleChange} value='Miss'>
                                  Miss.
                                </option>
                                <option key={'Dr'} onChange={Formik.handleChange} value='Dr'>
                                  Dr.
                                </option>
                                <option key={'Prof'} onChange={Formik.handleChange} value='Prof'>
                                  Prof.
                                </option>
                              </select>
                              {Formik.errors.fName ? (
                                <input type='text' name='fName' onChange={Formik.handleChange} value={Formik.values.fName} className='form-control is-invalid' placeholder='First Name' id='fName' />
                              ) : (
                                <input type='text' name='fName' onChange={Formik.handleChange} value={Formik.values.fName} className='form-control' placeholder='First Name' id='fName' />
                              )}
                              {Formik.errors.lastname ? (
                                <input type='text' name='lName' onChange={Formik.handleChange} value={Formik.values.lName} className='form-control is-invalid' placeholder='Last Name' id='lName' />
                              ) : (
                                <input type='text' name='lName' onChange={Formik.handleChange} value={Formik.values.lName} className='form-control' placeholder='Last Name' id='lName' />
                              )}
                            </div>
                          </div>

                          <div className='d-flex justify-content-between py-3'>
                            <div className='col-sm-5'>
                              {Formik.errors.phoneNumber ? (
                                <input type='text' name='phoneNumber' onChange={Formik.handleChange} className='form-control is-invalid' placeholder='Phone Number' id='phoneNumber' />
                              ) : (
                                <input type='text' name='phoneNumber' onChange={Formik.handleChange} className='form-control' placeholder='Phone Number' id='phoneNumber' />
                              )}
                            </div>

                            <div className='col-sm-5'>
                              {Formik.errors.username ? (
                                <input type='email' name='username' className='form-control is-invalid' onChange={Formik.handleChange} placeholder='Enter Your Email' id='username' />
                              ) : (
                                <input type='email' name='username' className='form-control' onChange={Formik.handleChange} placeholder='Enter Your Email' id='username' />
                              )}
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <button className='btn botton rounded-5 w-100 my-2' type='submit'>
                              Sign Up
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {/*------------------------------------------------------Third Section------------------------------------------------*/}
      {HomeMajor && HomeMajor.length ? (
        <div className='row'>
          <div key={'courses-section1'} className='courses-section1'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className='section-head text-center my-5'>
                    <h5 className='fs-4 title-section'>Specializations</h5>
                    <h3>Explore the most important majors</h3>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='row'>
                    {HomeMajor.map((el) => (
                      <div className='col-12 col-md-4 col-lg-3' key={el.id}>
                        <Link href='#'>
                          <div className='subject-item'>
                            <div className='subject-overlay'></div>
                            <div className='subject-data'>
                              <h5>{el.name}</h5>
                              <h6>{el.coursesCount}</h6>
                            </div>
                            <div className='subject-item-img'>
                              <img src={el.image ? el.image : img1} className='w-100' alt={el.name} />
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {/*---------------------------------------------------End Third Section------------------------------------------------*/}
      {/*------------------------------------------------------Four Section------------------------------------------------*/}
      {Homecourses && Homecourses.length ? (
        <div className='row'>
          <div key={'courses-section2'} className='courses-section2'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className='section-head text-center'>
                    <h5 className='title-section fs-4'>Courses</h5>
                    <h3>Highest rated courses</h3>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='row g-3'>
                    {Homecourses.map((course, idx) => (
                      <div className='col-12 col-md-4 col-lg-4' key={idx}>
                        <CourseBox course={course} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {/*--------------------------------------------------End Four Section------------------------------------------------*/}
      {/*----------------------------------------------------Five Section------------------------------------------------*/}
      {HomeInstuctors && HomeInstuctors.length ? (
        <div className='row'>
          <div key={'instructors-section'} className='container py-4'>
            <div className='row'>
              <div className='col'>
                <div className='section-head text-center'>
                  <h5 className='title-section fs-3'>Our instructor</h5>
                  <h6 className='fs-3'>The best educator ever</h6>
                </div>
                <OwlCarousel className='owl-theme' loop margin={10} items='3' autoplay dots dotsEach={true} touchDrag mouseDrag responsive={responsiveOptions}>
                  {HomeInstuctors.map((el) => (
                    <div key={el.id} className='item'>
                      <div className='teacher-item'>
                        <div className='teacher-img p-4'>{el.image ? <img src={el.image} alt='' className='w-50' /> : ''}</div>
                        <div className='teacher-name text-center'>
                          <h4>{el.name}</h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {/*--------------------------------------------------End Five Section------------------------------------------------*/}
    </>
  );
}
