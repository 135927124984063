import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Componants
import LayOut from './Componants/Shared/LayOut';
import Notfound from './Componants/Shared/Notfound';
import Home from './Componants/Website/Home';
import AboutUs from './Componants/Website/AboutUs';
import ContactUS from './Componants/Website/ContactUS';
import Login from './Componants/Account/Login';
import Courses from './Componants/Materials/Courses';
import ForgotPassword from './Componants/Account/ForgotPassword';
import Register from './Componants/Account/Register';
import Profile from './Componants/Account/Profile';
// import Globalpage from "./Componants/Shared/Globalpage";
import Course from './Componants/Materials/Course';
import Terms from './Componants/Website/Terms';
import Policy from './Componants/Website/Policy';
import Chapter from './Componants/Materials/Chapter';
import Cart from './Componants/Checkout/Cart';
import PaymentResult from './Componants/Checkout/PaymentResult';
import Universities from './Componants/Materials/Universities';

// Providers
import { UserProvider } from './Contexts/UserContext';
import { CartProvider } from './Contexts/CartContext';
import { LanguageProvider } from './Contexts/LanguageContext';
import { LoaderProvider } from './Contexts//LoaderContext';
import ConfirmEmail from './Componants/Account/ConfirmEmail';
import { ApiDataProvider } from './Contexts/ApiDataContext';

export default function App() {
  let routers = createBrowserRouter([
    {
      path: '',
      element: <LayOut />,
      children: [
        { path: 'home', index: true, element: <Home /> },
        { path: '/', element: <Home /> },
        { path: 'AboutUs', element: <AboutUs /> },
        { path: 'ContactUs', element: <ContactUS /> },
        { path: 'Terms', element: <Terms /> },
        { path: 'Policy', element: <Policy /> },

        { path: 'Courses', element: <Universities /> },
        { path: 'Courses/:universityId', element: <Courses /> },
        { path: 'Course/:courseId', element: <Course /> },
        { path: 'Chapter/:chapterId', element: <Chapter /> },

        { path: 'Register', element: <Register /> },
        { path: 'Login', element: <Login /> },
        { path: 'ConfirmEmail', element: <ConfirmEmail /> },
        { path: 'ForgotPassword', element: <ForgotPassword /> },

        { path: 'Profile', element: <Profile /> },
        { path: 'Checkout', element: <Cart /> },
        { path: 'PaymentResult', element: <PaymentResult /> },
        { path: '*', element: <Notfound /> },
      ],
    },
  ]);

  return (
    <>
      <LoaderProvider>
        <LanguageProvider>
          <ApiDataProvider>
            <UserProvider>
              <CartProvider>
                <RouterProvider router={routers} />
              </CartProvider>
            </UserProvider>
          </ApiDataProvider>
        </LanguageProvider>
      </LoaderProvider>
    </>
  );
}
