/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { LANG, APIURL, Loader } from '../IWWJsCode';

const ApiDataContext = React.createContext();

export function useApiData() {
  return useContext(ApiDataContext);
}

export function ApiDataProvider({ children }) {
  let { lang } = LANG();
  let { loading, setLoading } = Loader();
  let [universities, setUniversities] = useState();
  let [gettouch, setDatatouch] = useState();

  async function getUniversities(lng) {
    setLoading({ ...loading, Universities: true });
    fetch(APIURL(lng + '/Universities'))
      .then((res) => res.json())
      .then((json) => {
        setUniversities(json);
      });
    setLoading({ ...loading, Universities: false });
  }

  async function GetDatatouch(lng) {
    setLoading({ ...loading, Contact: true });
    fetch(APIURL(lng + '/web/contact'))
      .then((res) => res.json())
      .then((json) => {
        setDatatouch(json);
      });
    setLoading({ ...loading, Contact: false });
  }

  useEffect(() => {
    getUniversities(lang);
    GetDatatouch(lang);
  }, [lang]);

  return <ApiDataContext.Provider value={{ universities, gettouch }}>{children}</ApiDataContext.Provider>;
}
