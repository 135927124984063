import { LANG, CART } from '../../../IWWJsCode';

export default function CartButton({ id, price, type }) {
  let { currency, financialPrice } = LANG();
  let { cartItems, addItem } = CART();

  return price && price > 0 ? (
    <div className='btn-group' style={{ width: 'fit-content' }} role='group'>
      <button type='button' className='btn btn-dark'>
        {financialPrice(price)} {currency}
      </button>
      <button
        type='button'
        className='btn btn-outline-warning'
        disabled={cartItems.some((i) => i.id === id && i.type === type)}
        onClick={() => {
          addItem({ id: id, type: type });
        }}>
        <i className='fas fa-cart-plus'></i>
      </button>
    </div>
  ) : (
    <></>
  );
}
