/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import overlay from '../../images/overlay-bottom.png';
import phoneFormatImage from '../../images/phone_e164.png';
import * as Yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { USER, APIURL } from '../../IWWJsCode';

export default function Register() {
  let navigate = useNavigate();
  let { user, setUser } = USER();

  let [message, setMessage] = useState();
  let [TimeZones, SetTimeZones] = useState([]);
  let [Universities, SetUniversities] = useState([]);
  let [Colleges, SetColleges] = useState([]);
  let [Majors, SetMajors] = useState([]);

  function CheckUser() {
    if (user != null) navigate('/profile');
  }

  let validationSchema = Yup.object({
    title: Yup.string().required(),
    fName: Yup.string().required('Required').min(3, 'Min char is 3 ').max(10, 'Max char is 10'),
    lName: Yup.string().required('Required').min(3, 'Min char is 3 ').max(10, 'Max char is 10'),
    phoneNumber: Yup.string()
      .required()
      .matches(/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/, 'Enter an available phone number with international (E 164) format.{+12345678900}'),
    username: Yup.string().required().email('Enter a valid email'),
    type: Yup.string().required(),
    localZone: Yup.string().required(),
    universityId: Yup.string().required(),
    collegeId: Yup.string().required(),
    majorId: Yup.string().required(),
    passcode: Yup.string().min(6).required('choose a password'),
    confirmPasscode: Yup.string().oneOf([Yup.ref('passcode'), null], 'Passwords not matches'),
    termsAgreement: Yup.boolean().oneOf([true], 'You must read the terms and conditions and complete the registration by agreeing to them.'),
  });

  let initVal = {
    title: 'Mr',
    fName: '',
    lName: '',
    phoneNumber: '',
    username: '',
    type: 'Student',
    localZone: '',
    universityId: '',
    collegeId: '',
    majorId: '',
    termsAgreement: false,
  };

  const registerData = JSON.parse(localStorage.getItem('RegisterData'));
  if (registerData != null) {
    if (registerData.title != null) initVal.title = registerData.title;
    if (registerData.fName != null) initVal.fName = registerData.fName;
    if (registerData.lName != null) initVal.lName = registerData.lName;
    if (registerData.phoneNumber != null) initVal.phoneNumber = registerData.phoneNumber;
    if (registerData.username != null) initVal.username = registerData.username;
    if (registerData.type != null) initVal.type = registerData.type;
    if (registerData.localZone != null) initVal.localZone = registerData.localZone;
    initVal.universityId = registerData.localZone ?? '';
  }

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values, { setSubmitting }) => {
      localStorage.setItem('RegisterData', JSON.stringify(values));
      console.log('PostRegister', values);
      axios
        .post(APIURL('auth/register'), values)
        .then((resp) => {
          setMessage(resp.data.message);
          console.log('resp.data.message', resp.data.message);
          console.log('resp.data', resp.data);

          if (resp.data.user) {
            localStorage.removeItem('RegisterData');
            setUser(resp.data.user);
            setTimeout(() => {
              navigate('/profile');
            }, 2000);
          } else {
            setSubmitting(false);
          }
        })
        .catch((error) => {
          console.error('formik.Submitting:Error:', error);
          setSubmitting(false);
          setMessage({ success: false, message: 'Something went wrong' });
        });
    },
    validationSchema,
  });

  useEffect(() => {
    GetTimeZones();
    CheckUser();
    GetUniversities();
  }, []);

  function ArrayPrepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  async function GetTimeZones() {
    let { data } = await axios.get(APIURL('auth/timeZones')).catch((error) => {
      alert(error);
    });
    SetTimeZones(ArrayPrepend({ value: '', name: 'Select Your local Timezone' }, data));
  }

  async function GetUniversities() {
    let { data } = await axios.get(APIURL('en/Universities/Get'));
    SetUniversities(ArrayPrepend({ id: '', name: 'Select Your University' }, data));
    initVal.universityId = '';
    OnChangeUniversity(data[0].value);
  }
  async function GetColleges(id) {
    let { data } = await axios.get(APIURL(`en/Universities/Colleges?id=${id}`));

    initVal.universityId = id;
    SetColleges(ArrayPrepend({ id: '', name: 'Select Your College' }, data));
    OnChangeCollege(data[0].value);
  }

  async function GetMajors(id) {
    let { data } = await axios.get(APIURL(`en/Universities/Majors?id=${id}&uid=${initVal.universityId}`));
    initVal.collegeId = id;
    SetMajors(ArrayPrepend({ id: '', name: 'Select Your Major' }, data));
  }

  function OnChangeUniversity(id) {
    if (id !== '' && id !== null) {
      SetColleges([{ id: '', name: 'loading..' }]);
      GetColleges(id);
    } else {
      SetColleges([{ id: '', name: 'Select University First' }]);
    }
    SetMajors([{ id: '', name: 'Select College First' }]);
  }

  function OnChangeCollege(id) {
    if (id !== '' && id !== null) {
      SetMajors([{ id: '', name: 'loading..' }]);
      GetMajors(id);
    } else {
      SetMajors([{ id: '', name: 'Select College First' }]);
    }
  }

  // User Type on change
  function OnChangeUserType() {
    if (document.getElementById('TypeStudent').checked) {
      formik.values.type = 'Student';
    } else {
      formik.values.type = 'Instructor';
    }
  }

  document.querySelector('body').addEventListener('change', function () {
    if (document.getElementById('TypeStudent').checked) {
      document.getElementById('form_check_type').style.marginTop = '2px';
    } else {
      document.getElementById('form_check_type').style.marginTop = '-26px';
    }
  });

  return (
    <>
      <div className='shape-menu'>
        <img src={overlay} alt='' className='w-100' />
      </div>
      <div className='login-page'>
        <div className='container'>
          <div className='form-section'>
            <div className='row'>
              <div className='col-md-8 col-sm-12 m-auto'>
                <div className='form-request rounded-3 bg-white'>
                  <div className='row'>
                    <form onSubmit={formik.handleSubmit}>
                      <div className='d-flex justify-content-between'>
                        <div className='input-group'>
                          <div className='form-floating'>
                            <select className='form-control' onChange={formik.handleChange} id='title' name='title' defaultValue={initVal.title}>
                              <option onChange={formik.handleChange} value='Mr'>
                                Mr.
                              </option>
                              <option onChange={formik.handleChange} value='Mrs'>
                                Mrs.
                              </option>
                              <option onChange={formik.handleChange} value='Miss'>
                                Miss.
                              </option>
                              <option onChange={formik.handleChange} value='Dr'>
                                Dr.
                              </option>
                              <option onChange={formik.handleChange} value='Prof'>
                                Prof.
                              </option>
                            </select>
                            <label htmlFor='title'>Title</label>
                          </div>
                          <div className='form-floating'>
                            <input type='text' name='fName' onChange={formik.handleChange} className={'form-control' + (formik.errors.fName ? ' is-invalid' : '')} placeholder='First Name' defaultValue={initVal.fName} id='fName' />
                            <label htmlFor='fName'>First Name</label>
                          </div>
                          <div className='form-floating'>
                            <input type='text' name='lName' onChange={formik.handleChange} defaultValue={initVal.lName} className={'form-control' + (formik.errors.lName ? ' is-invalid' : '')} placeholder='Last Name' id='LName' />
                            <label htmlFor='lName'>Last Name</label>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between py-3'>
                        <div className='col-sm-5 mb-3'>
                          <div className='form-floating'>
                            <input type='text' name='phoneNumber' onChange={formik.handleChange} className={'form-control' + (formik.errors.phoneNumber ? ' is-invalid' : '')} placeholder='Phone Number' defaultValue={initVal.phoneNumber} id='phoneNumber' />
                            <label htmlFor='phoneNumber'>Phone Number</label>
                          </div>
                          {formik.errors.phoneNumber ? <img className='d-block m-auto' width={'50%'} src={phoneFormatImage} alt='Phone Number format example' /> : ''}
                        </div>

                        <div className='col-sm-6'>
                          <div className='form-floating'>
                            <input type='email' name='username' className={'form-control' + (formik.errors.username ? ' is-invalid' : '')} onChange={formik.handleChange} defaultValue={initVal.username} placeholder='Enter Your Email' id='username' />
                            <label htmlFor='Username'>Your Email</label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex py-3'>
                        <div className='col-sm-12'>
                          <div className='form-floating'>
                            <select className={'form-control' + (formik.errors.localZone ? ' is-invalid' : '')} onChange={formik.handleChange} id='localZone' name='localZone' autoFocus>
                              {TimeZones.map((el) => (
                                <option key={el.value} value={el.value} onChange={formik.handleChange}>
                                  {el.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor='localZone'>Your Local TimeZone</label>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-sm-5'>
                          <div className='form-floating'>
                            <select
                              className={'form-control ms-1' + (formik.errors.universityId ? ' is-invalid' : '')}
                              onChange={(e) => {
                                formik.handleChange(e);
                                OnChangeUniversity(e.target.value);
                              }}
                              id='universityId'
                              name='universityId'
                              defaultValue={initVal.universityId}>
                              {Universities.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor='universityId'>Your University</label>
                          </div>
                        </div>
                        <div className='col-sm-7'>
                          <div className='form-floating'>
                            <select
                              className={'form-control ms-1' + (formik.errors.collegeId ? ' is-invalid' : '')}
                              onChange={(e) => {
                                formik.handleChange(e);
                                OnChangeCollege(e.target.value);
                              }}
                              id='collegeId'
                              name='collegeId'
                              defaultValue={initVal.collegeId}>
                              {Colleges.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor='CollegeId'>Your College</label>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <div className='col-sm-12'>
                          <div className='form-floating'>
                            <select className={'form-control ms-1' + (formik.errors.majorId ? ' is-invalid' : '')} onChange={formik.handleChange} id='majorId' name='majorId' defaultValue={initVal.majorId}>
                              {Majors.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor='majorId'>Your Major</label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex'>
                        <div className='col-sm-12'>
                          <div className='input-group'>
                            <div className='form-floating'>
                              <input type='password' name='passcode' onChange={formik.handleChange} className={'form-control' + (formik.errors.passcode ? ' is-invalid' : '')} placeholder='Password' id='passcode' />
                              <label htmlFor='passcode'>Password</label>
                            </div>
                            <div className='form-floating'>
                              <input type='password' name='confirmPasscode' onChange={formik.handleChange} className={'form-control' + (formik.errors.lName ? ' is-invalid' : '')} placeholder='Confirm Password' id='confirmPasscode' />
                              <label htmlFor='confirmPasscode'>Confirm Password</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className='col-sm-12'>
                        <div className='d-flex my-4'>
                          <p>I register as a</p>
                          <div id='form_check_type'>
                            <div className='form-check mx-1'>
                              <input className='form-check-input' type='radio' name='type' value='Student' id='TypeStudent' onChange={(e) => OnChangeUserType()} defaultChecked />
                              <label className='form-check-label' htmlFor='TypeStudent'>
                                Student,
                              </label>
                            </div>
                            <div className='form-check mx-1'>
                              <input className='form-check-input' type='radio' name='type' value='Instructor' id='TypeInstructor' onChange={(e) => OnChangeUserType()} />
                              <label className='form-check-label' htmlFor='TypeInstructor'>
                                Instructor,
                              </label>
                            </div>
                          </div>
                          <div className='form-check form-check-inline mx-1'>
                            <input className='form-check-input' type='checkbox' id='termsAgreement' name='termsAgreement' value='true' onChange={formik.handleChange} />
                            <label className={'form-check-label ' + (formik.errors.termsAgreement != null ? 'fw-bold text-danger' : '')} style={{ cursor: 'pointer' }} htmlFor='termsAgreement'>
                              and I agree to
                            </label>
                            <a href='/terms' className='mx-1 pe-auto'>
                              the terms and conditions.
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 overflow-hidden'>
                        {formik.errors.passcode ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.passcode}</span> : ''}
                        {formik.errors.confirmPasscode ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.confirmPasscode}</span> : ''}
                        {formik.errors.phoneNumber ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.phoneNumber}</span> : ''}
                        {formik.errors.username != null ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.username}</span> : ''}
                        {formik.errors.termsAgreement != null ? <span className='badge text-bg-danger text-wrap text-uppercase m-2'>{formik.errors.termsAgreement}</span> : ''}
                        {message ? (
                          <div className={'alert w-100 text-wrap alert-' + (message.success ? 'success' : 'danger')}>
                            <p>{message.message}</p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='d-flex justify-content-center'>
                        <button className='btn botton rounded-5 w-100 my-2' disabled={formik.isSubmitting || formik.errors.termsAgreement ? 'disabled' : ''} type='submit'>
                          {formik.isSubmitting ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : 'Sign Up'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
