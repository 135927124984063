import { stagger, useAnimate, usePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GET_IMAGE_URL, LANG, USER } from '../../../IWWJsCode';
import CartButton from './_CartButton';

export default function CourseBox({ course }) {
  let { currency } = LANG();
  let { isUserCourse } = USER();


  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimate = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 2, delay: stagger(1.5) });
      };
      enterAnimate()
    } else {
      const exitAnimate = async () => {
        await animate(scope.current, { opacity: [1, 0] }, { duration: 2, delay: stagger(1.5) });
        safeToRemove()
      };
      exitAnimate()
    }
  });


  return (
    <div className='courses-item position-relative'>
      <Link ref={scope} key={course.id} to={'/Course/' + course.id} className='text-decoration-none'>
        <div className='overflow-hidden'>
          <div className='courses-item-img'>
            <img src={GET_IMAGE_URL(course.image)} alt={course.name} className='w-100' />
          </div>
        </div>
        <div className='courses-data position-relative'>
          {course.durationInHours && course.durationInHours > 0 ? (
            <h6 className='text-muted'>
              <i className='fas fa-clock mr-1'></i>
              {course.durationInHours} hour
            </h6>
          ) : (
            ''
          )}
          <p className={'position-absolute top-0 end-0 ' + (course.active ? 'text-success' : 'text-danger')}>{course.endDate}</p>
          <h6>{course.name}</h6>
          <p>{course.summary}</p>
          <hr />
          {course.priceBefore && course.priceBefore > 0 ? (
            <h5 className='price-free'>
              {course.priceBefore} {currency}
            </h5>
          ) : (
            ''
          )}
        </div>
      </Link>
      <div className='position-absolute bottom-0 start-0 p-1'>
        {isUserCourse(course.id) ? <span className='badge text-bg-secondary'>Your's</span> : !course.isFree && course.price ? <CartButton price={course.price} type='Course' id={course.id} /> : <span className='btn-warning free'>Paid</span>}
      </div>
    </div>
  );
}
