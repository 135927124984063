import React, { useEffect, useState } from "react";
import overlay from "../../images/overlay-bottom.png";
import { useFormik } from "formik";
import * as Yup from 'yup'
import axios from "axios";


export default function ContactUS() {
  let [contactData, setContactData] = useState({})
  let [BranchData, setBranchData] = useState([])

  useEffect(() => {
    GetContact()
  }, [])
  useEffect(() => {
    GetBranchs()
  }, [])

  async function GetContact() {
    let { touchData } = await axios.get("URL").then((respon) => {
      // Handle response
      console.log(respon.data);
      setContactData(respon.data)
    })
      .catch((eror) => {
        // Handle errors
        console.error(eror);
      });
  }
  async function GetBranchs() {
    let { touchData } = await axios.get("URL").then((respon) => {
      // Handle response
      console.log(respon.data);
      setBranchData(respon.data)
    })
      .catch((eror) => {
        // Handle errors
        console.error(eror);
      });
  }


  let validationSchema = Yup.object({
    Name: Yup.string().required(),
    Phone: Yup.string().required(),
    Email: Yup.string().required(),
    Message: Yup.string().required(),
  })

  const initialValues = {
    Name: '',
    Phone: '',
    Email: '',
    Message: '',
  }


  const onSubmit = values => {
    console.log(values);
  }

  const Formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  })

  return (
    <>
      {/* <div className="shape-menu">
        <img src={overlay} alt="" srcSet="" className="w-100" />
      </div>
      <div className="contact-page1">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-11 m-auto">
              <div className="row">
                <div className="contact-page1">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-5 m-auto">
                        <div className="contact-form">
                          <h3>Send a Massage</h3>
                          <form onSubmit={Formik.handleSubmit} >
                            {Formik.errors.Name ? <input
                              type="text"
                              className="form-control is-invalid"
                              placeholder="Name *"
                              name="Name"
                              id="Name"
                              onChange={Formik.handleChange}
                              value={Formik.values.Name}
                            /> : <input
                              type="text"
                              className="form-control"
                              placeholder="Name *"
                              name="Name"
                              id="Name"
                              onChange={Formik.handleChange}
                              value={Formik.values.Name}
                            />}

                            {Formik.errors.Phone ? <input
                              type="text"
                              className="form-control is-invalid"
                              placeholder="Phone *"
                              name="Phone"
                              id="Phone"
                              onChange={Formik.handleChange}
                              value={Formik.values.Phone}
                            /> : <input
                              type="text"
                              className="form-control"
                              placeholder="Phone *"
                              name="Phone"
                              id="Phone"
                              onChange={Formik.handleChange}
                              value={Formik.values.Phone}
                            />}
                            {Formik.errors.Email ? <input
                              type="email"
                              className="form-control is-invalid"
                              placeholder="Email *"
                              name="Email"
                              id="Email"
                              onChange={Formik.handleChange}
                              value={Formik.values.Email}
                            /> : <input
                              type="email"
                              className="form-control"
                              placeholder="Email *"
                              name="Email"
                              id="Email"
                              onChange={Formik.handleChange}
                              value={Formik.values.Email}
                            />}

                            {Formik.errors.Message ? <textarea
                              name="Message"
                              id="Message"
                              rows="4"
                              className="form-control is-invalid"
                              placeholder="Your Massage ..."
                              onChange={Formik.handleChange}
                              value={Formik.values.Message}
                            ></textarea> : <textarea
                              name="Message"
                              id="Message"
                              rows="4"
                              className="form-control"
                              placeholder="Your Massage ..."
                              onChange={Formik.handleChange}
                              value={Formik.values.Message}
                            ></textarea>}

                            <button type="submit" className="btn btn-primary form-control">
                              Send
                            </button>
                          </form>
                        </div>
                      </div>
                      <div className="col-12 col-md-7">
                        <div className="contact-data">
                          <h3>{contactData.title}</h3>
                          <ul className="list-unstyled">
                            <li className="text-white">
                              <i className={IconName + " fs-5 text-white mx-2"}></i>
                              {contactData.address}
                            </li>
                            <li className="text-white">
                              <a
                                href="tel:+"
                                className=" text-white mx-2 text-decoration-none"
                              >
                                <i className={" fs-5 text-white mx-2"}></i>
                                {contactData.telephone}
                              </a>
                            </li>
                            <li>
                              <i className={IconName + " fs-5 text-white mx-2"}></i>
                              <h6>{contactData.whatsapp}</h6>
                            </li>
                            <li className="text-white">
                              <a
                                href="mailto:"
                                className="text-decoration-none  text-white "
                              >
                                <i className={IconName + " fs-5 text-white mx-2"}></i>
                                {gettouch.email}
                              </a>
                            </li>
                            <li>
                              <div className="social">
                                <ul className="list-unstyled d-flex">
                                  {gettouch && gettouch.length ? gettouch.map((el) => (

                                    <li className="m-2">
                                      <a to={el} target="_blank" >
                                        <i className={IconName + " fs-5 text-white"}></i>
                                      </a>
                                    </li>
                                  )) :
                                    ""}
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {BranchData.map((el) => (
                  <>
                    <div className="col-12 mt-10">
                      <div className="contact-item">
                        <div className="row">
                          <div className="col-12 col-md-5">
                            <div className="contact-data">
                              <h3>{el.title}</h3>
                              <ul className="list-unstyled">
                                <li className="text-white">
                                  <i className={IconName + " fs-5 text-white mx-2"}></i>
                                  {el.address}
                                </li>
                                <li className="text-white">
                                  <a
                                    href="tel:+"
                                    className=" text-white mx-2 text-decoration-none"
                                  >
                                    <i className={IconName + " fs-5 text-white mx-2"}></i>
                                    {el.telephone}
                                  </a>
                                </li>
                                <li>
                                  <i className={IconName + " fs-5 text-white mx-2"}></i>
                                  <h6>{el.whatsapp}</h6>
                                </li>
                                <li className="text-white">
                                  <a
                                    href="mailto:"
                                    className="text-decoration-none  text-white "
                                  >
                                    <i className={IconName + " fs-5 text-white mx-2"}></i>
                                    {el.email}
                                  </a>
                                </li>
                                <li>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {el.iframe ? <div className="col-12 col-md-7">
                      <div className="map">
                        <iframe
                          src={el}
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div> : ""}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
