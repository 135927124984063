import { USER } from '../../../IWWJsCode';
import CartButton from './_CartButton';
import ChapterBox from './_ChapterBox';

export default function SectionBox({ section, key }) {
  let { isUserSection } = USER();

  return (
    <ul className='list-group mt-3'>
      <li key={key} className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
        <h4>{section.name}</h4>
        {isUserSection(section) ? (
          <>
            <span className='badge text-bg-secondary float-end'>Your's</span>
          </>
        ) : section.isFree ? (
          <span className='badge text-bg-success float-end'>FREE</span>
        ) : (
          <CartButton price={section.price} type='Section' id={section.id} />
        )}
      </li>
      {section.chapters && section.chapters.length && section.chapters.map((chapter, chapkey) => <ChapterBox chapter={chapter} key={chapkey} />)}
    </ul>
  );
}
