/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { APIURL, LANG, USER, GET_IMAGE_URL, Loader } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';
import CartButton from './Partials/_CartButton';

export default function Chapter() {
  let { lang } = LANG();
  let { loading, setLoading } = Loader();
  let { chapterId } = useParams();
  let { user } = USER();
  let [error, setError] = useState();
  let [chapter, setChapter] = useState();
  let [dataLoading, setDataLoading] = useState(false);
  let [videoId, setVideoId] = useState();
  let [video, setVideo] = useState();
  let [bookId, setBookId] = useState();
  let [book, setBook] = useState();

  let [authorized, setAuthorized] = useState({
    auth: false,
    error: null,
  });

  useEffect(() => {
    getChapter(chapterId);
  }, [chapterId]);

  async function getChapter(id) {
    setError(null);

    if (id && id !== '') {
      setLoading(true);
      fetch(APIURL(lang + `/chapters/chapter?id=${id}`))
        .then((res) => res.json())
        .then((json) => {
          if (json && json.authorized)
            setAuthorized(json.authorized);
          if (json && json.chapter)
            setChapter(json.chapter);
          if (json.error)
            setError(json.error);
          setLoading(false);
        });
    } else {
      setChapter(null);
    }
  }

  useEffect(() => {
    if (authorized && authorized.auth && videoId && videoId !== '') {
      playVideo(videoId);
    }
  }, [videoId]);

  async function playVideo(id) {
    setError(null);
    if (user && user.Active && id && id !== '') {
      setDataLoading(true);
      fetch(APIURL(lang + `/chapters/video?id=${id}&chapterId=${chapterId}`))
        .then((res) => res.json())
        .then((json) => {

          if (json.authorized) {
            setAuthorized(json.authorized);
          }

          if (json.video) {
            setVideo(json.video);
          }

          if (json.error) {
            setError(json.error);
          }

          if (book || bookId) {
            setBook(null);
            setBookId(null);
          }

          setDataLoading(false);
        });
    }
  }

  useEffect(() => {
    if (authorized && authorized.auth && bookId && bookId !== '') {
      renderBookPage(bookId);
    }
  }, [bookId]);

  async function renderBookPage(id, index) {
    setError(null);
    setDataLoading(true);

    if (user && user.Active && id && id !== '') {
      fetch(APIURL(lang + `/chapters/book?id=${id}&chapterId=${chapterId}`))
        .then((res) => res.json())
        .then((json) => {
          if (json && json.authorized) {
            setAuthorized(json.authorized);
          }

          if (json.book) {
            setBook(json.book);
          }

          if (json.error) {
            setError(json.error);
          }

          if (video || videoId) {
            setVideo(null);
            setVideoId(null);
          }

          setDataLoading(false);
        });
    } else {
      setDataLoading(false);
    }
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-4'>
            <SecLoader type={'chapter'} />
            {authorized && authorized.auth && chapter ? (
              <>
                <Link className='btn btn-outline-dark' to={'/Course/' + chapter.courseId}>
                  Back to {chapter.name}
                  <i className='mx-1 fas fa-arrow-left'></i>
                </Link>

                <ul class='list-group mt-3'>
                  {chapter && chapter.videos.length ? (
                    <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                      <h5>Videos</h5>
                    </li>
                  ) : (
                    ''
                  )}
                  {chapter && chapter.videos.length
                    ? chapter.videos.map((vdo, cindex) => (
                      <>
                        <li key={cindex} className='list-group-item d-flex justify-content-between align-items-start'>
                          <div class='ms-2 me-auto overflow-hidden'>
                            <h6 class='fw-bold text-wrap'>{vdo.name}</h6>
                            <span className='badge text-bg-dark'>
                              <i class='fas fa-stopwatch mx-1'></i>
                              {vdo.time}
                            </span>
                          </div>

                          <button
                            type='button'
                            className={'btn btn-outline-' + (videoId === vdo.id ? 'success' : 'secondary')}
                            disabled={videoId === vdo.id}
                            onClick={() => {
                              setVideoId(vdo.id);
                            }}>
                            <i class={videoId === vdo.id ? 'fas fa-compact-disc fa-spin' : 'fas fa-play-circle'}></i>
                          </button>
                        </li>
                      </>
                    ))
                    : ''}
                </ul>
                <ul class='list-group mt-3'>
                  {chapter && chapter.books.length ? (
                    <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                      <h5>Books</h5>
                    </li>
                  ) : (
                    ''
                  )}

                  {chapter && chapter.books.length
                    ? chapter.books.map((book, cindex) => (
                      <>
                        <li key={cindex} className='list-group-item d-flex justify-content-between align-items-start'>
                          {/* <img src={book.image} className="h-100 w-auto" alt={book.name} /> text-truncate */}
                          <div class='ms-2 me-auto overflow-hidden'>
                            <h6 class='fw-bold text-wrap'>{book.name}</h6>
                            {book.pagesCount && book.pagesCount > 0 ? (
                              <span className='badge text-bg-dark m-1'>
                                <i className='fas fa-copy mx-1'></i>
                                {book.pagesCount} page
                              </span>
                            ) : (
                              ''
                            )}

                            {book.pdf && book.pdf !== '' ? (
                              <>
                                <a href={book.pdf} download={book.pdfName} className='badge text-wrap text-bg-success my-1'>
                                  <i className='fas fa-cloud-download-alt mx-1'></i>
                                  {book.pdfName}
                                </a>
                              </>
                            ) : (
                              ''
                            )}
                          </div>

                          <button
                            type='button'
                            className={'btn btn-outline-' + (bookId === book.id ? 'success' : 'secondary')}
                            disabled={bookId === book.id}
                            onClick={() => {
                              setBookId(book.id);
                            }}>
                            <i class={bookId === book.id ? 'fas fa-book-open' : 'fas fa-book'}></i>
                          </button>
                        </li>
                      </>
                    ))
                    : ''}
                </ul>
              </>
            ) : (
              ''
            )}
          </div>
          <div className='col-12 col-lg-8 position-sticky' style={{ top: '5px' }}>
            {error && error !== '' ? (
              <div className='alert alert-danger'>
                <h3>Error</h3>
                <hr />
                <p>{error}</p>
              </div>
            ) : (
              ''
            )}

            {authorized && authorized.auth && chapter ? (
              <>
                {dataLoading ? (
                  <div class='d-flex justify-content-center'>
                    <div class='spinner-border text-warning' style={{ width: '3rem', height: '3rem' }} role='status'>
                      <span class='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {book == null && video == null ? (
                      <div class='card mb-3'>
                        <div class='row g-0'>
                          <div class={chapter && chapter.instructor && chapter.instructor.image ? 'col-md-8' : 'col-md-12'}>
                            <div class='card-body'>
                              <h5 class='card-title'>{chapter.instructor.name}</h5>
                              <h5 class='card-title'>{chapter.name}</h5>
                            </div>
                          </div>
                          {chapter && chapter.instructor && chapter.instructor.image ? (
                            <div class='col-md-4'>
                              <img src={GET_IMAGE_URL(chapter.instructor.image)} class='img-fluid rounded-end' alt={chapter.name} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : book == null && video != null ? (
                      <div className='row'>
                        <div className='col-md-12'>
                          {video.url ? (
                            <div className='ratio ratio-16x9'>
                              <iframe src={video.url} style={{ border: '0' }} allow='encrypted-media' allowfullscreen={'allowfullscreen'}></iframe>
                            </div>
                          ) : (
                            <div className='alert alert-warning'>
                              <h4 className='alert-heading'>{video.name} in progress.</h4>
                              <hr />
                              <p>
                                It may take some time, if it takes more than 30 minutes, please <Link to={'/contactus'}>inform</Link> the administrators.
                              </p>
                            </div>
                          )}
                          <h3>{video.name}</h3>
                        </div>
                      </div>
                    ) : book && ((book.pages && book.pages.length) || book.pdf) ? (
                      <div className='row'>
                        {book.pdf ? (
                          <>
                            <div className='col-12' style={{ border: '0', height: '780px' }}>
                              <iframe src={book.pdf} style={{ border: '0', minHeight: '100%', width: '100%' }} allow='encrypted-media' allowfullscreen={'allowfullscreen'}></iframe>
                            </div>
                          </>
                        ) : (
                          <>
                            {book.pages.map((page) => (
                              <div className='col-md-12 mb-2 img-thumbnail' style={{ position: 'relative' }}>
                                <h3>
                                  {'(' + page.index + ')'} {page.title}
                                </h3>
                                <span
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    right: '0',
                                    bottom: '0',
                                    zIndex: '1071',
                                    cursor: 'default',
                                  }}></span>
                                <img src={page.image} className='w-100' style={{ height: 'auto' }} alt='book page' />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            ) : (
              ''
            )}

            {!chapter && !loading && authorized && !authorized.auth ? (
              <div class='alert alert-light' role='alert'>
                <h4 class='alert-heading'>Unauthorized</h4>
                {authorized.error ? <p class='mb-0'>{authorized.error}</p> : ''}
                <hr />
                {user && (!authorized || !authorized.auth) ? (
                  chapter && chapter.price && chapter.price > 0 ? (
                    <>
                      <CartButton price={chapter.price} type='Chapter' id={chapter.id} />
                      <p>This account not authorized to view content</p>
                    </>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {!user ? (
                  <p>
                    <Link to='/login'>Login</Link> required login if you have account or <Link to='/login'>register</Link> a new account
                  </p>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
}
