import React from 'react';
import logo from '../../images/logo.png';
import { NavLink } from 'react-router-dom';
import { USER, CART } from '../../IWWJsCode';
import { useApiData } from '../../Contexts/ApiDataContext';

export default function Navbar() {
  let { universities } = useApiData();
  let { cartItems } = CART();
  const { user, loadingUser } = USER();

  return (
    <>
      <nav className='navbar  navbar-expand-lg'>
        <div className='container p-md-1 px-md-5 '>
          <NavLink className='navbar-brand  fs-3 fw-bold ps-md-5 ' to='/home'>
            <img src={logo} className='logo' alt='' />
          </NavLink>
          <div className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </div>
          <div className='collapse navbar-collapse pe-md-5' id='navbarSupportedContent'>
            <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <NavLink className={({ isActive }) => (isActive === true ? 'nav-link active' : 'nav-link')} aria-current='page' to='/home'>
                  Home
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className={({ isActive }) => (isActive === true ? ' nav-link active' : 'nav-link')} aria-current='page' to='aboutus'>
                  About Us
                </NavLink>
              </li>
              {universities && universities.length ? (
                <li className='nav-item dropdown'>
                  <NavLink className={({ isActive }) => (isActive === true ? ' dropdown-toggle nav-link ' : ' dropdown-toggle nav-link ')} href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    Universities
                  </NavLink>
                  <ul className='dropdown-menu'>
                    {universities.map((el) => (
                      <li key={el.id}>
                        <NavLink className={({ isActive }) => (isActive === true ? 'dropdown-item' : 'dropdown-item')} to={'/Courses/' + el.id}>
                          {el.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : (
                ''
              )}

              <li className='nav-item'>
                <NavLink className={({ isActive }) => (isActive === true ? ' nav-link active' : 'nav-link')} aria-current='page' to='contactus'>
                  Contact Us
                </NavLink>
              </li>
              <li className='nav-item'>
                {loadingUser ? (
                  <span className='btn rounded-pill bttn'>
                    <i className='fas fa-circle-notch text-light fa-spin'></i>
                  </span>
                ) : (
                  <>
                    {user != null ? (
                      <NavLink className='btn rounded-pill bttn' aria-current='page' to='profile'>
                        {user.FName}
                      </NavLink>
                    ) : (
                      <NavLink className='btn rounded-pill bttn' aria-current='page' to='login'>
                        Login
                      </NavLink>
                    )}
                  </>
                )}
              </li>
              <li className='nav-item'>
                <NavLink className='btn p-1 border-0 position-relative ms-2' aria-current='page' to='Checkout'>
                  <i className='fas fa-shopping-cart cart'></i>
                  <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning'>{cartItems.length}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
