import React, { useEffect, useState } from "react";
import axios from "axios";
import overlay from "../../images/overlay-bottom.png";
import { GET_IMAGE_URL, APIURL } from "../../IWWJsCode";

export default function Policy() {
  let [policyData, setpolicyData] = useState();

  useEffect(() => {
    getpolicyData();
  }, []);

  async function getpolicyData() {
    let { data } = await axios.get(APIURL("en/web/policy")).catch((err) => {
      // Handle errors
      console.error(err);
    });
    setpolicyData(data);
  }

  return policyData && policyData.length ? (
    <>
      <div className='shape-menu'>
        <img src={overlay} alt='' srcSet='' className='w-100' />
      </div>
      <div className='new-about mt-5 about-page'>
        <div className='container'>
          {policyData.map((el, i) => (
            <div key={i} className='row mb-2'>
              <div className='col-12 col-md-12'>
                {el.image || el.image2 ? (
                  <div className='about-img img-inline'>
                    <img src={GET_IMAGE_URL(el.image, el.image2)} alt='' srcSet='' className='w-100' />
                  </div>
                ) : (
                  ''
                )}
                <h3 className='head-section'>{el.title}</h3>
                <div className='about-data' dangerouslySetInnerHTML={{ __html: el.htmlDescription }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  ) : (
    ''
  );
}
