import React, { useEffect, useState } from 'react';
import overlay from '../../images/overlay-bottom.png';
import logo from '../../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { USER, APIURL } from '../../IWWJsCode';

export default function Login() {
  let navigate = useNavigate();
  let [loading, setLoading] = useState();
  let [errorMessage, setErrorMessage] = useState();
  const { user, setUser, getUser } = USER();

  function CheckUser() {
    setLoading(true);
    getUser();
    if (user != null) navigate('/profile');
    setLoading(false);
  }

  let initVal = {
    username: '',
    passcode: '',
    rememberMe: false,
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      axios
        .post(APIURL('auth/login'), values)
        .then((resp) => {
          setErrorMessage(resp.data);
          if (resp.data.success) {
            if (resp.data.user) setUser(resp.data.user);
          }
          setLoading(false);
          setSubmitting(false);
        })
        .catch((error) => {
          console.error('Error', error);
          if (error.status !== 200) {
            setErrorMessage(error.response.data);
          }
          setLoading(false);
          setSubmitting(false);
        });
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required()
        .email('Enter an available email'),
      passcode: Yup.string()
        .min(4)
        .required('Password is required'),
      rememberMe: Yup.boolean(),
    }),
  });

  useEffect(() => {
    CheckUser();
  }, [user]);

  return (
    <>
      <div className='row'>
        <div className='shape-menu'>
          <img src={overlay} alt='' className='w-100' />
        </div>
        <div className='login-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-5 m-auto'>
                <form onSubmit={formik.handleSubmit} className='login-form'>
                  <div className='row'>
                    <div className='col-6 m-auto'>
                      <div className='login-logo text-center'>
                        <img src={logo} className='w-50' alt='logo' />
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-12'>
                      <div className='form-floating'>
                        <input type='email' name='username' className={'form-control' + (formik.errors.username ? ' is-invalid' : '')} onChange={formik.handleChange} placeholder='Enter Your Email' id='username' />
                        <label htmlFor='username'>Email</label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='form-floating'>
                        <input type='password' name='passcode' className={'form-control' + (formik.errors.passcode ? ' is-invalid' : '')} onChange={formik.handleChange} placeholder='Enter Your Password' id='passcode' />
                        <label htmlFor='passcode'>Password</label>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='form-check'>
                        <input className='form-check-input' type='checkbox' id='rememberMe' name='rememberMe' onChange={formik.handleChange} />
                        <label className='form-check-label' htmlFor='rememberMe'>
                          Remember Me
                        </label>
                      </div>
                    </div>
                    {errorMessage ? <span className={'badge text-wrap text-bg-' + (errorMessage.success ? 'success' : 'danger') + ' w-100 my-2'} dangerouslySetInnerHTML={{ __html: errorMessage.message }}></span> : ''}
                    <button type='submit' className='btn bttn rounded-pill w-100 text-white' disabled={formik.isSubmitting || loading || formik.errors.passcode || formik.errors.username ? 'disabled' : ''}>
                      {loading ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : 'Login'}
                    </button>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-12 col-md-6'>
                      <a href='/Account/ForgotPassword' className='link'>
                        <p>Forgot your password ?</p>
                      </a>
                    </div>
                    <div className='col-12 col-md-6 creat-account'>
                      <Link to={'/register'} className='link'>
                        <p className='text-end'>Create Account</p>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
