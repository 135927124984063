import React, { useEffect, useState } from 'react';
import { USER, APIURL, CART, LANG, Loader } from '../../IWWJsCode';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import SecLoader from '../Shared/Loaders/SecLoader';

export default function PaymentResult() {
  const [searchParams] = useSearchParams();
  const { getUser } = USER();
  const { lang } = LANG();
  const { emptyCart } = CART();
  let [payment, setPayment] = useState();
  let [resultData, setResultData] = useState();
  let [message, setMessage] = useState({ success: null, message: null });
  const { loading, setLoading } = Loader();
  let data = searchParams.get('data');
  let id = searchParams.get('id');

  useEffect(() => {
    if (data) getResultData(data);
  }, [data]);

  useEffect(() => {
    if (id) getPaymentData(id);
  }, [id]);

  function getResultData(d) {
    if (d && d.length) {
      setLoading(true);
      fetch(APIURL(`${lang}/payments/PaymentResult?data=${d}`))
        .then((res) => res.json())
        .then((json) => {
          if (json) {
            if (json.payment) {
              setPayment(json.payment);
              if (json.payment.paid && json.payment.isNewPayment) {
                emptyCart();
                getUser();
              }
            }

            if (json.paymentResponse) setResultData(json.paymentResponse);
            if (json.message) setMessage(json.message);
          }
          setLoading(false);
        });
    }
  }

  function getPaymentData(id) {
    if (id && id.length) {
      setLoading(true);
      fetch(APIURL(`${lang}/payments/PaymentTransactions?paymentId=${id}`))
        .then((res) => res.json())
        .then((json) => {
          if (json) {
            if (json.payment) {
              setPayment(json.payment);
              if (json.payment.paid && json.payment.isNewPayment) {
                emptyCart();
                getUser();
              }
            }

            // if (json.paymentResponse) setResultData(json.paymentResponse);
            if (json.message) setMessage(json.message);
          }
          console.log('json', json);
          setLoading(false);
        });
    }
  }

  return (
    <>
      {message && message.message ? (
        <div className={'alert ' + (message.success ? 'alert-success' : 'alert-warning')}>
          <p>{message.message}</p>
        </div>
      ) : (
        ''
      )}
      <div className='container'>
        <div className='row'>
          {loading ? (
            <SecLoader type={'receipt'} />
          ) : (
            <>
              <div className='col-md-12'>
                <div class='card'>
                  {!loading && payment ? (
                    <div class='card-body mx-4'>
                      <div class='container'>
                        <p class='my-5 mx-5' style={{ fontSize: '30px' }}>
                          {payment.paid ? (
                            <>
                              <i class='far fa-check-circle text-success mx-2'></i>
                              Thank for your purchase
                            </>
                          ) : (
                            <>
                              <i class='far fa-times-circle text-danger  mx-2'></i>
                              No effective payment has been made
                              <span className='badge bg-light text-wrap text-primary'>Please Check receipt again, if you completed the payment.</span>
                              <span className='badge bg-light text-wrap text-primary' style={{direction: 'rtl'}}>ربما يتطلب عمليه الدفع اقل من دقيقة لإتمام، ارجو اعادة طلب الإصال للتاكد من عمليه الدفع من الصفحة الشخصية الخاصة بك.</span>
                            </>
                          )}
                        </p>
                        <div class='row'>
                          <ul class='list-unstyled'>
                            <li class='text-black'>{payment.userName}</li>
                            {payment.transactions && payment.transactions.length
                              ? payment.transactions.map((tr) => (
                                  <>
                                    <li class='text-muted mt-1'>
                                      <span class='text-black float-end'>{tr.date}</span>
                                      <span class='text-black'>
                                        {tr.merchant}Invoice #{tr.invoiceNumber}
                                      </span>
                                      {tr.resultCode === 'Paid' ? <h5 className='text-success'>SUCCESS</h5> : tr.resultCode === 'Failed' ? <h5 className='text-danger'>FAILED</h5> : <h5 className='text-warning'>{tr.resultCode}</h5>}

                                      <hr />
                                    </li>
                                  </>
                                ))
                              : ''}
                          </ul>
                          <hr />
                        </div>
                        {payment.items && payment.items.length
                          ? payment.items.map((it) => (
                              <>
                                <div class='row'>
                                  <div class='col-xl-10'>
                                    <p>
                                      {it.type} - {it.name}
                                    </p>
                                  </div>
                                  <div class='col-xl-2'>
                                    <p class='float-end'>
                                      {it.price} - {payment.currency}
                                    </p>
                                  </div>
                                  <hr />
                                </div>
                              </>
                            ))
                          : ''}
                        <div class='row text-black'>
                          <hr style={{ border: '2px solid black' }} />
                          <div class='col-xl-12'>
                            <p class='float-end fw-bold'>
                              Total: {payment.total} {payment.currency}
                            </p>
                          </div>
                          <hr style={{ border: '2px solid black' }} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='d-none'>
        <h3>Payment Result</h3>
        {resultData ? <p>{JSON.stringify(resultData)}</p> : ''}
      </div>
    </>
  );
}
