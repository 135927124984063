import React from 'react';
import { Loader } from '../../../IWWJsCode';

export default function Footer() {
  let { loading } = Loader();

  return (
    <>
      <div className='position-fixed bottom-0 start-0 end-0' style={{ display: loading ? 'block' : 'none', zIndex: 11 }}>
        <div className='progress d-flex rounded-0' style={{ height: '20px' }}>
          <div className='progress-bar progress-bar-striped bg-warning progress-bar-animated' role='progressbar' aria-label='Loading...' style={{ width: '100%' }} aria-valuenow='100' aria-valuemin='0' aria-valuemax='100'></div>
        </div>
      </div>
    </>
  );
}
