import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { APIURL, Loader } from '../../IWWJsCode';

export default function ConfirmEmail() {
  let { setLoading } = Loader();
  // let { userId, code } = useParams();
  const [searchParams] = useSearchParams();

  let [userId, setUserId] = useState();
  let [code, setCode] = useState();
  let [message, setMessage] = useState();

  useEffect(() => {
    setUserId(searchParams.get('userId'));
    setCode(searchParams.get('code'));
  }, []);

  useEffect(() => {
    CheckCode(userId, code);
  }, [userId, code]);

  async function CheckCode(_userId, _code) {
    // setLoading(true);
    let { data } = await axios.get(APIURL(`auth/confirmEmail?userId=${_userId}&code=${_code}`)).catch((error) => {
      console.error('CheckCode:ERROR', error);
    });
    if (data && data.message) setMessage(data.message);
    // setLoading(false);
  }

  return (
    <>
      <div className='row'>
        <div className='login-page'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-md-5 m-auto'>
                {message ? (
                  <div className={'alert w-100 text-wrap alert-' + (message.success ? 'success' : 'danger')}>
                    <h3>
                      {message.success ? <i className='mx-1 fas fa-check-circle text-success'></i> : <i className='mx-1 fas fa-exclamation-circle text-danger'></i>}
                      {message.message}
                    </h3>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
