import { React, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { USER, APIURL, Loader, LANG } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';

export default function Profile() {
  const navigate = useNavigate();
  let [reSendLoading, setReSendLoading] = useState(false);
  const { user, setUser, getUser } = USER();
  let { currency, financialPrice } = LANG();
  let { loading, setLoading } = Loader();
  let [reSend, setReSend] = useState(false);

  function CheckUser() {
    if (user == null) navigate('/login');
  }

  async function Logout(e) {
    e.preventDefault();
    setLoading(true);

    fetch(APIURL('auth/Logout'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);
      });

    await axios.post(APIURL('auth/Logout'));
    setUser(null);
    navigate('/login');
  }

  async function resendConfirmationLink(e) {
    e.preventDefault();
    setReSendLoading(true);
    await axios.post(APIURL('auth/resendConfirmationLink'));
    setReSend(true);
    setReSendLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getUser();
    CheckUser();
    setLoading(false);
  }, []);

  return user && user.Active ? (
    <>
      <div className='login-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <SecLoader type={'cart'} />
              {user.coursesRender && user.coursesRender.length ? (
                <ul class='list-group mt-3'>
                  <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                    <h4>
                      <i className='fas fa-compact-disc mx-2'></i>My Courses
                    </h4>
                  </li>
                  {user.coursesRender.map((course, sindex) => (
                    <li key={sindex} className='list-group-item d-flex justify-content-between align-items-start'>
                      <div class='ms-2 me-auto'>
                        <div class='fw-bold'>{course.name}</div>
                      </div>
                      <NavLink key={sindex} className='btn btn-outline-success' to={'/course/' + course.id}>
                        Go to Course
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              {user.orders && user.orders.length ? (
                <ul class='list-group mt-3'>
                  <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                    <h4>
                      <i className='fas fa-file-invoice-dollar mx-2'></i>My Orders
                    </h4>
                  </li>
                  {user.orders.map((order, sindex) => (
                    <li key={sindex} className='list-group-item d-flex justify-content-between align-items-start'>
                      <div class='col-4'>
                        <p class='fw-bold'>{order.date}</p>
                      </div>
                      <div className='col-4'>{'(' + order.items + ')'} Items</div>
                      <div className='col-4'>
                        <Link to={'/PaymentResult?id=' + order.id} className={'btn w-100 btn-outline-' + (order.paid ? 'success' : 'danger')}>
                          {!order.paid ? 'Check' : ''} Receipt {financialPrice(order.amount)} {currency}
                          <i className='fas fa-file-invoice mx-1'></i>
                        </Link>
                        {!order.paid ? <p className='text-info'>Re-check that the payment has been completed.</p> : ''}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </div>
            <div className='col-md-4'>
              {user && user.Image ? (
                <div className='d-flex mb-4 align-self-center align-items-center justify-content-center'>
                  <img src={user.Image} className='rounded-circle border border-3 border-light' style={{ height: '150px', width: '150px', objectFit: 'cover' }} alt={user.name} />
                </div>
              ) : (
                ''
              )}
              <div className={'form-control text-center' + (user.Active ? '' : 'text-danger')}>{user.Name}</div>

              {!user.EmailConfirmed && !reSend ? (
                <>
                  <button
                    type='button'
                    className={'btn my-2 btn-light text-warning w-100' + (reSendLoading ? ' disabled' : '')}
                    disabled={reSendLoading ? 'disabled' : ''}
                    onClick={(e) => {
                      resendConfirmationLink(e);
                    }}>
                    Verify {user.Email}, Resend verification link
                    {reSendLoading ? <i className='fas fa-circle-notch mx-1 fa-spin'></i> : <i className='fas mx-1 fa-redo-alt'></i>}
                  </button>
                </>
              ) : reSend ? (
                <span className='badge badge-success'>Link sent successfully to {user.Email}</span>
              ) : (
                ''
              )}

              {user.Manager ? (
                <a className={'btn btn-lg btn-primary mb-2 w-100'} href='/InstructorManage'>
                  Instructor Dashboard
                </a>
              ) : (
                ''
              )}

              <button
                type='button'
                className={'btn btn-lg btn-danger w-100' + (loading ? ' disabled' : '')}
                disabled={loading ? 'disabled' : ''}
                onClick={(e) => {
                  Logout(e);
                }}>
                {loading ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : 'Logout'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}
