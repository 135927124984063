import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAnimate, stagger, usePresence } from 'framer-motion';

import { USER } from '../../../IWWJsCode';
import CartButton from './_CartButton';

export default function ChapterBox({ chapter, key }) {
  let { isUserChapter } = USER();

  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimate = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 1, delay: stagger(0.5) });
      };
      enterAnimate()
    } else {
      const exitAnimate = async () => {
        await animate(scope.current, { opacity: [1, 0] }, { duration: 1, delay: stagger(0.5) });
        safeToRemove()
      };
      exitAnimate()
    }
  });

  return (
    <li ref={scope} key={key} className='list-group-item'>
      <div className='w-100'>
        <h5 className='fw-bold'>{chapter.name}</h5>
        <div className='float-end'>
          {chapter.isFree || isUserChapter(chapter) ? (
            <Link className='btn btn-outline-success' to={'/Chapter/' + chapter.id}>
              {chapter.isFree ? 'FREE' : 'OPEN'}
            </Link>
          ) : (
            <>{chapter.price > 0 ? <CartButton price={chapter.price} type='Chapter' id={chapter.id} /> : ''}</>
          )}
        </div>
      </div>
      <div className='w-100'>
        {chapter.videosCount && chapter.videosCount > 0 ? (
          <span className='badge text-bg-light'>
            <i className='fas fa-video mx-1'></i>
            {chapter.videosCount}
          </span>
        ) : (
          ''
        )}
        {chapter.booksCount && chapter.booksCount > 0 ? (
          <span className='badge text-bg-light'>
            <i className='fas fa-book mx-1'></i>
            {chapter.booksCount}
          </span>
        ) : (
          ''
        )}
      </div>
    </li>
  );
}
