import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";

const CartContext = createContext({});

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  let [cartItems, setCartItems] = useState(
    localStorage.getItem("IWWCart")
      ? JSON.parse(localStorage.getItem("IWWCart"))
      : []
  );

  const addItem = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      alert("item aleady added");
    } else {
      setCartItems([...cartItems, item]);
    }
  };

  const removeItem = (id) => {
    // const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== id));
  };

  const emptyCart = () => {
    setCartItems([]);
  };

  // const getCartTotal = () => {
  //   return cartItems.reduce((total, item) => total + item.price, 0);
  // };

  useEffect(() => {
    localStorage.setItem("IWWCart", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("IWWCart");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  return (
    <>
      <CartContext.Provider
        value={{ cartItems, addItem, removeItem, emptyCart}}
      >
        {children}
      </CartContext.Provider>
    </>
  );
}
