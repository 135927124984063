/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { LANG, APIURL, Loader } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';
import CourseBox from './Partials/_CourseBox';

export default function Courses() {
  let { lang } = LANG();
  let { loading, setLoading } = Loader();

  let [courses, setCourses] = useState(null);

  // get id from url params
  let [University, SetUniversity] = useState();
  let [Colleges, SetColleges] = useState([]);
  let [Majors, SetMajors] = useState([]);
  let [Instructors, SetInstructors] = useState([]);

  // filter form data
  let { universityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({ colId: '', majId: '', insId: '', txt: '' });
  const searchTxt = searchParams.get('txt');
  const collegeId = searchParams.get('colId');
  const majorId = searchParams.get('majId');
  const instructorId = searchParams.get('insId');

  useEffect(() => {
    getCourses();
  }, [universityId, searchTxt, collegeId, majorId, instructorId]);

  useEffect(() => {
    if (universityId) getUniversity(universityId);
    getColleges();
  }, [universityId]);

  async function getUniversity(uid) {
    setLoading(true);
    SetUniversity();
    fetch(APIURL(lang + `/Universities/byId?id=${uid}`))
      .then((res) => res.json())
      .then((json) => {
        SetUniversity(json);
        setLoading(false);
      });
  }

  async function getCourses() {

    setLoading(true);
    setCourses(null);
    fetch(APIURL(lang + `/Courses?searchTxt=${searchTxt}&universityId=${universityId}&collegeId=${collegeId}&majorId=${majorId}&instructorId=${instructorId}`))
      .then((res) => res.json())
      .then((json) => {
        setCourses(json);
        setLoading(false);
      });
  }

  async function getColleges() {
    fetch(APIURL(`${lang}/Universities/Colleges?id=${universityId}`))
      .then((res) => res.json())
      .then((json) => {
        SetColleges([{ id: '', name: 'Select College' }, ...json]);
      });
  }

  async function getMajors() {
    SetMajors([{ id: '', name: 'loading..' }]);
    fetch(APIURL(`${lang}/Universities/Majors`))
      .then((res) => res.json())
      .then((json) => {
        SetMajors([{ id: '', name: 'Select Your Major' }, ...json]);
      });
    setSearchParams(
      (prev) => {
        prev.set('majId', '');
        return prev;
      },
      { replace: true }
    );
  }

  async function getInstructors() {
    SetInstructors([{ id: '', name: 'loading..' }]);

    fetch(APIURL(`${lang}/Courses/instructors?universityId=${universityId}&collegeId=${collegeId}&majorId=${majorId}`))
      .then((res) => res.json())
      .then((json) => {
        SetInstructors([{ id: '', name: 'Select Instructor' }, ...json]);
      });

    setSearchParams(
      (prev) => {
        prev.set('insId', '');
        return prev;
      },
      { replace: true }
    );
  }

  function onChangeCollege(_collegeId) {
    setSearchParams(
      (prev) => {
        prev.set('colId', _collegeId);
        return prev;
      },
      { replace: true }
    );
    if (_collegeId != null) {
      getMajors();
    } else {
      SetMajors([{ id: '', name: 'Select College First' }]);
    }
    getInstructors();
  }

  function onChangeMajor(_majorId) {
    setSearchParams(
      (prev) => {
        prev.set('majId', _majorId);
        return prev;
      },
      { replace: true }
    );

    getInstructors();
  }

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-3'>
          <div className='position-sticky top-0'>
            {loading ? (
              <>
                <div className='w-100 text-center'>
                  <i className='fas fa-circle-notch text-warning fa-spin'></i>
                </div>
              </>
            ) : (
              <>
                {University ? (
                  <>
                    <div className='section-head text-center'>
                      {University.image ? <img src={University.image} alt={University.name} className='mw-100' height={'150px'} /> : ''}
                      <h3>{University.name} Courses</h3>
                    </div>
                  </>
                ) : (
                  <div className='section-head text-center'>
                    <h5 className='title-section fs-4'>COURSES SECTION</h5>
                    <h3>WITH US, YOUR SUPERIORITY IS GUARANTEED</h3>
                  </div>
                )}
              </>
            )}

            <div className='row justify-content-center'>
              <div className='col-12 col-md-12'>
                <div className='input-group'>
                  <div className='form-floating'>
                    <input
                      id='searchTxt'
                      className='form-control'
                      placeholder='Course Name'
                      onBlur={(e) => {
                        setSearchParams(
                          (prev) => {
                            prev.set('txt', e.target.value);
                            return prev;
                          },
                          { replace: true }
                        );
                      }}
                    />
                    <label htmlFor='searchTxt'>Search</label>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-12'>
                <div className='form-floating'>
                  <select
                    id='collegeId'
                    className={'form-control'}
                    onChange={(e) => {
                      onChangeCollege(e.target.value);
                    }}>
                    {Colleges &&
                      Colleges.length &&
                      Colleges.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor='collegeId'>College</label>
                </div>
              </div>
              <div className='col-12 col-md-12'>
                <div className='form-floating'>
                  <select
                    id='majorId'
                    className={'form-control'}
                    onChange={(e) => {
                      onChangeMajor(e.target.value);
                    }}>
                    {Majors &&
                      Majors.length &&
                      Majors.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor='majorId'>Major</label>
                </div>
              </div>
              <div className='col-12 col-md-12'>
                <div className='form-floating'>
                  <select
                    id='instructorId'
                    className={'form-control'}
                    onChange={
                      ((e) => {
                        setSearchParams((prev) => {
                          prev.set('insId', e.target.value);
                          return prev;
                        });
                      },
                        { replace: true })
                    }>
                    {Instructors &&
                      Instructors.length &&
                      Instructors.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor='instructorId'>Instructor</label>
                </div>
              </div>
              {/* <div className='col-12 col-md-12'>
                <button
                  className='input-group-text w-100 my-2 btn btn-warning bttn'
                  onClick={(e) => {
                    getCourses();
                  }}
                  type='button'>
                  Search
                </button>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-12 col-md-9'>
          <div className='row'>
            {loading && courses == null ? (
              <>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
              </>
            ) : (
              ''
            )}

            {courses && courses.length > 0 ? (
              courses.map((course, idx) => (
                <div className='col-12 mb-2 col-md-4' key={idx}>
                  <CourseBox course={course} />
                </div>
              ))
            ) : (
              <>
                {!loading && courses && courses.length === 0 ? (
                  <h3 className='text-muted text-center text-secondary'>
                    <i className='far fa-images mx-2'></i>
                    No courses found
                  </h3>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
