import { React, useState } from "react";
import overlay from "../../images/overlay-bottom.png";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
export default function ForgotPassword() {
  const [username, setUsername] = useState();

  return (
    <>
      <div className="shape-menu">
        <img src={overlay} alt="" className="w-100" />
      </div>
      <div className="login-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 m-auto">
              <div className="login-form">
                <div className="row">
                  <div className="col-6 m-auto">
                    <div className="login-logo text-center">
                      <img src={logo} className="w-50" alt="logo" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label forHtml="username">Username/Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    aria-describedby="usernameHelp"
                    placeholder="Username/Email *"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Link
                    href="#"
                    className="btn bttn rounded-pill w-100 text-white"
                  >
                    Sent Reset Code
                  </Link>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-6">
                    <Link to="/login" className="link">
                      <p>Try Again to login</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
