/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';

import { Loader } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';
import { useApiData } from '../../Contexts/ApiDataContext';

export default function Universities() {
  let { universities } = useApiData();
  let { loading } = Loader();

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-8'>
          <div className='row'>
            {loading && universities == null ? (
              <>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
                <div className='col-12 mb-2 col-md-4'>
                  <SecLoader type='courses' />
                </div>
              </>
            ) : (
              ''
            )}

            {universities && universities.length > 0 ? (
              universities.map((univ, idx) => (
                <div className='col-12 mb-2 col-md-4' key={idx}>
                  <Link to={'/Courses/' + univ.id} className='card h-100 nav-link'>
                    <img src={univ.image} className='card-img-top h-100' style={{objectFit: 'contain'}} alt='' />
                    <h4 className='text-center'>{univ.name}</h4>
                  </Link>
                </div>
              ))
            ) : (
              <>
                {!loading && universities && universities.length === 0 ? (
                  <h3 className='text-muted text-center text-secondary'>
                    <i className='far fa-university mx-2'></i>
                    No Universities
                  </h3>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
