import { createContext, useContext, useEffect, useState } from "react";
import { APIURL } from '../IWWJsCode';

const LanguageContext = createContext();

export function useLang() {
  return useContext(LanguageContext);
}

export function LanguageProvider({ children }) {
  let [lang, setLang] = useState("en");
  let [currency, setCurrency] = useState('rCUR');

  useEffect(() => {
    getCurrency(lang);
  }, [lang]);

  async function getCurrency(lng) {
    fetch(APIURL(lng + '/Settings/Currency'))
      .then((res) => res.json())
      .then((json) => {
       setCurrency(json);
      });
  }

  function financialPrice(price) {
    return Number.parseFloat(price).toFixed(2);
  }

  return <LanguageContext.Provider value={{ lang, setLang, currency, financialPrice }}>{children}</LanguageContext.Provider>;
}
