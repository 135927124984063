import settings from "./settings.json";
import { useUser } from "./Contexts/UserContext";
import { useCart } from "./Contexts/CartContext";
import { useLang } from "./Contexts/LanguageContext";
import { useLoader } from "./Contexts/LoaderContext";
import defaultImage from "./images/logo-footer.png";

export function LANG() {
  return useLang();
}
export function Loader() {
  return useLoader();
}

export function HostURL(file) {
  return settings.Host + file;
}

export function APIURL(page) {
  return settings.APiUrl + page;
}

export function ArrayPrepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export function USER() {
  return useUser();
}

export function CART() {
  return useCart();
}
export function GET_IMAGE_URL(image, other) {
  if (!image || image === "") return (other || other !== '')? other : defaultImage;
  return image;
}
