import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { useAnimate, usePresence } from 'framer-motion';

import { APIURL, LANG, GET_IMAGE_URL, Loader, USER } from '../../IWWJsCode';
import SecLoader from '../Shared/Loaders/SecLoader';
import ChapterBox from './Partials/_ChapterBox';
import SectionBox from './Partials/_SectionBox';
import CartButton from './Partials/_CartButton';

export default function Course() {
  // const [isPresent, safeToRemove] = usePresence();
  // const [scope, animate] = useAnimate();
  let { courseId } = useParams();
  let { lang } = LANG();
  let { isUserCourse } = USER();
  let { loading, setLoading } = Loader();
  // let navigate = useNavigate();
  let [course, setCourse] = useState(null);
  let [sections, setSections] = useState([]);
  let [chapters, setChapters] = useState([]);

  useEffect(() => {
    getCourse(courseId);
  }, [courseId]);

  async function getCourse(id) {
    if (id && id !== '') {
      setLoading(true);

      fetch(APIURL(lang + `/courses/getById?id=${id}`))
        .then((res) => res.json())
        .then((json) => {
          setCourse(json);
          setSections(json.sections);
          setChapters(json.chapters);
          setLoading(false);
        });
    }
  }

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5'>
            {course && course.university ? (
              <Link className='btn btn-outline-dark' to={'/Courses/' + course.university.Id}>
                Back to {course.university.Name} Courses
                <i className='mx-1 fas fa-arrow-left'></i>
              </Link>
            ) : (
              ''
            )}
            <SecLoader type={'chapter'} />
            {chapters && chapters.length ? (
              <ul class='list-group mt-3'>
                {chapters.map((chap, cindex) => (
                  <ChapterBox chapter={chap} key={cindex} />
                ))}
              </ul>
            ) : (
              ''
            )}
            {sections && sections.length > 0 ? sections.map((sec, index) => <SectionBox section={sec} key={index} />) : ''}
          </div>
          <div className='col-md-7'>
            {/* <SecLoader type={'default'} /> */}
            {course ? (
              <div class='card mb-3 position-sticky' style={{ top: '5px' }}>
                <div class='row g-0'>
                  <div class={course.image || course.instructor || course.instructor.image ? 'col-md-8' : 'col-md-12'}>
                    <div class='card-body'>
                      {course.instructor ? <h5 class='card-title'>{course.instructor.name}</h5> : ''}
                      {course.name ? <h5 class='card-title'>{course.name}</h5> : ''}
                      {course.summary ? <p class='card-text'>{course.summary}</p> : ''}

                      <div class='card-text'>
                        {course.university ? <p class='mb-0'>{course.university.Name}</p> : ''}
                        {course.college ? <p class='mb-0'>{course.college.Name}</p> : ''}
                        {course.major ? <p class='mb-0'>{course.major.Name}</p> : ''}
                      </div>
                      {course.durationInHours && course.durationInHours > 0 ? (
                        <p class='card-text'>
                          <small class='text-muted'>{course.durationInHours} Hours</small>
                        </p>
                      ) : (
                        ''
                      )}
                      {isUserCourse(course.id) ? (
                        <>
                          <span className='badge text-bg-secondary'>Your's</span>
                        </>
                      ) : course.isFree ? (
                        <span class='btn-success btn'>Free</span>
                      ) : !course.isFree && course.price ? (
                        <CartButton price={course.price} type='Course' id={course.id} />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {course.image || course.instructor || course.instructor.image ? (
                    <div class='col-md-4'>
                      <img src={GET_IMAGE_URL(course.image, course.instructor && course.instructor.image ? course.instructor.image : '')} class='inner-course-img img-fluid rounded-end' alt={course.name} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <>
                {!loading && !course ? (
                  <div className='alert alert-light'>
                    <p>Course currently not found</p>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
